import { RENTAL_APPLICATION_NOT_FOUND } from '~/assets/strings';
import { rentalApplicationGateway } from '~/gateways/rentalApplicationGateway';
import { MagicUseCase } from '../magicUseCase';
import { GetRentalApplicationUseCase } from './getRentalApplicationUseCase';

export class LockRentalApplicationUseCase extends MagicUseCase {
  protected async runLogic() {
    const application = this.getState().user.rentalApplication.application;
    if (!application) {
      throw new Error(RENTAL_APPLICATION_NOT_FOUND);
    }
    if (!application.isDraft) {
      return;
    }
    await rentalApplicationGateway.lockRentalApplication(application?.credentials);
    await new GetRentalApplicationUseCase().execute(application?.credentials);
  }
}
